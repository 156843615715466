import i18next from "i18next";
import LanguageManagment from "./Language/LanguageManagement";

const RouteRoleMap = {
  facilityManager: "/home",
  eventManager: "/home",
  securityManager: "/home",
  operationManager: "/home/lightManagement"
};

class UserManagement {
  setUserAndRole(name, role) {
    this.name = name;
    this.role = role;
  }

  getUserName() {
    return this.name;
  }

  getUserRole() {
    return this.role;
  }

  getDefaultRoute() {
    return RouteRoleMap[this.role];
  }

  getHasAccepted() {
    return this.hasAccepted;
  }

  setUserDetail(data) {
    this.name = data.userName;
    this.hasAccepted = data.hasAccepted;
    this.eulaContent = data.eulaContent;
    this.openLicense = data.openLicense;
    LanguageManagment.setUserLanguage(data.language);
    i18next.changeLanguage(data.language);
  }

  getIsControlUiUser() {
    return this.isControlUiUser;
  }

  getisSchedulingApplicationUser() {
    return this.isSchedulingApplicationUser;
  }

  getEULAContent() {
    return this.eulaContent;
  }

  getOpenLicense() {
    return this.openLicense;
  }
}

const instance = new UserManagement();
export default instance;
