import React from "react";
// import i18next from "i18next";
// import * as Labels from "../constants/Labels";
import OpenLicenseContent from "./OpenLicenseContent";

const OpenLicense = () => {
  return (
    <div className="tou-container pt-4">
      <p className="tou-content-mega-header">
        Overview of Acknowledgements and License Texts for open source software
      </p>
      <p>This overview provides license information and acknowledgements for the following product(s):</p>
      <p>
        1. Interact Sports
      </p>
      <p>
        Interact Sports Application Webportal
      </p>
      <p>
        This Interact Sports Application Webportal makes use of software licensed under an open source license. When <br />
        interacting with the webportal you may receive software that is executed in your browser. The list below provides an <br />
        overview of acknowledgements/license texts of open source software that may be distributed during your <br />
        interactions with the webportal used in this product.
      </p>
      <OpenLicenseContent />
    </div>
  );
}

export default OpenLicense;