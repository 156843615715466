import React from "react"
import UserManagement from "../UserManagement";

const OpenLicenseContent = () => {
  const content = UserManagement.getOpenLicense();
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default OpenLicenseContent;
