import React from "react";
import i18next from "i18next";
import { NavLink, Route } from "react-router-dom";
import { AboutList, AboutTypes } from "../constants/AppConstants";
import AboutContent from "./AboutContent";
import Termsofuse from "./Termsofuse";
// import LanguageSelector from "./Language/LanguageSelector";
import OpenLicense from "./OpenLicense";

const About = () => {
  const navlinks = AboutList.map(item => {
    const itemWithoutSpaces = item.replace(/ +/g, "");
    return (
      <li key={item} className="pr-4 mr-2 Monitor-tab">
        <NavLink
          activeClassName="eua-monitor-selected-navlink-header"
          className="m-0 pt-2 pb-2"
          to={`/${itemWithoutSpaces}`}
        >
          {i18next.t(item.toUpperCase())}
        </NavLink>
      </li>
    );
  });
  return (
    <>
      <header className="eua-monitor-header row w-100 row-margin p-0 m-0">
        <ul className="row eua-monitor-menu pr-5 pl-4 ml-0">{navlinks}</ul>
      </header>
      <div className="about-container">
        <Route path={`/${AboutTypes.About}`} exact component={AboutContent} />
        <Route
          path={`/${AboutTypes.TermsOfUse.replace(/ +/g, "")}`}
          exact
          component={Termsofuse}
        />
        <Route 
          path={`/${AboutTypes.OpenLicense.replace(/ +/g, "")}`}
          exact
          component={OpenLicense}
        />
      </div>
    </>
  );
};

export default About;
