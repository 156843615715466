export const DEFAULT_ERROR_MESSAGE = "Unknown Error Occurred";

export const BASE_URL = CONFIG.API_URL;
// export {DEFAULT_ERROR_MESSAGE,BASE_URL};
export const { STAGE } = CONFIG;

export const ApplicationTypes = {
  Schedule: "Schedule",
  Control: "Control",
  Monitor: "Monitor",
  Facility: "Facility",
  OpenApi: "OpenApi",
  MasterSchedule: "MasterSchedule"
};

export const Applications = [
  {
    Key: 1,
    Value: ApplicationTypes.Schedule
  },
  {
    Key: 2,
    Value: ApplicationTypes.Control
  }
];

export const MasterSchedule = {
  Key: 3,
  Value: ApplicationTypes.MasterSchedule
};

export const MonitorApplication = {
  Key: 3,
  Value: ApplicationTypes.Monitor
};

export const AboutTypes = {
  About: "About",
  TermsOfUse: "License_Agreement",
  OpenLicense: "Open_License"
};

export const AboutList = [AboutTypes.About, AboutTypes.TermsOfUse, AboutTypes.OpenLicense];
